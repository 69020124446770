
.main {
  position: relative;
  height: 100%;
  background: linear-gradient(180deg, #000 0%, #2B2B2B 100%);
  overflow: hidden;
  margin: auto;
}

.container {
  max-width: 1600px;
  margin: auto;
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
}
