@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html {
  font-size: 16px;
  background: #000;
  margin: 0;
} /*16px*/

body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  margin: 0;
}

h3 {font-size: 1.953rem;}

h4 {font-size: 1.563rem;}

h5 {font-size: 1.25rem;}

small, .text_small {font-size: 0.8rem;}

img {
  -webkit-user-drag: none !important;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}

.section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sub-section-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
  border: 1px solid rgba(217, 217, 217, 0.20);
  background: #070707;
  width: 50%;
}

.sub-section-text {
  font-size: 0.9rem;
  line-height: 1.8rem;
  font-weight: var(--header-font-weight);
  color: #bdbdbd;
  overflow-wrap: break-word;
}

@media only screen and (max-width: 1483px) {

  .sub-section-text {
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 993px) {

  .sub-section-text {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 872px) {
  .sub-section-container {
    width: 22rem;
  }

  .sub-section-text {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 499px) {
}

@media only screen and (max-width: 430px) {
  .sub-section-container {
    width: 100%;
  }
}


/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 767px) {

  .section-container {
    width: 100%;
  }

  .section-container {
    margin-bottom: var(--row-gap);
  }
}



/* Laptops */
@media only screen and (min-width: 961px) and (max-width: 1211px) {

  html {
    font-size: 17px;
  }

}



/* Desktop and Wide screen */
@media only screen and (min-width: 1212px) {

  html {
    font-size: 21px;
  }
}



:root {
  /* left && right page padding */
  --page-padding: 1.5rem;

  /* menu items padding */
  --menu-item-left-padding: 1rem;

  /* button padding */
  --button-px: 1rem;
  --button-py: 0.1rem;

  /* header margin font line-height */
  --header-font-weight: 600;
  --header-line-height: 1.3;

  /* h1 attributes */
  --h1-font-size: 1.7rem;

  /* h2 attributes */
  --h2-font-size: 1.35rem;

  /* p attributes */
  --p-font-size: 1rem;

  /* p2 attributes */
  --p2-font-size: 0.8rem;

  /* grid item bottom margin */
  --grid-item-bm: 1.75rem;

  /* vertical grid spacing */
  --row-gap: 3.5rem;

  /* section bottom margin */
  --section-bm: 4rem;
}